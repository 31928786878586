import React, { FunctionComponent } from "react"
import ArticleCard from "../components/ArticleCard"
import SEO from "../components/seo/seo"
import RichTextRenderer from "../components/rich-text-handler/rich-text-handler"
import "../assets/styles/page/docArticlePage.scss"
import Layout from "../components/layout"
import Tweetbutton from "../components/tweetbutton"
import Facebookshare from "../components/facebookshare"
import { Link } from "gatsby"

const Articlepage: FunctionComponent = pageContext => {
  const {
    metaDescription,
    metaTitle,
    titleOfArticle,
    articleBody,
    bannerUrl,
    aboutAuthor,
    authorImage,
    contentful_id
  } = pageContext?.pageContext.edge

  const articleArray = pageContext.pageContext.data

  return (
    <div>
      <SEO title={titleOfArticle} description={metaTitle} image={bannerUrl.fluid.src} />
      <Layout>
        <div className="article--content--container">
          <div className="article--content--inner">
            <article className="main--article--content">
              <div className="article--banner--wrapper">
                <div
                  className="banner--image--wrapper"
                  style={{
                    backgroundImage: `url(${bannerUrl.fluid.src})`
                  }}
                ></div>
                <div className="banner--overlap--wrapper">
                  <div className="banner--overlap--text--inner">
                    <h2 className="banner--overlap--headtext">{titleOfArticle}</h2>
                    <p className="banner--overlap--subtext">{metaTitle}</p>
                    <p className="meta">{metaDescription}</p>
                  </div>
                </div>
              </div>
              <div className="article--main--content">
                <RichTextRenderer content={articleBody.json} />
              </div>
              <div className="share--article--media">
                <Facebookshare />
                <Tweetbutton titleOfArticle={titleOfArticle} />
              </div>
              <div className="article--author--details--wrapper">
                <div
                  className="author--image"
                  style={{
                    backgroundImage: `url(${authorImage.fluid.src})`
                  }}
                ></div>
                <div className="about--author">
                  <p>{aboutAuthor}</p>
                </div>
              </div>
            </article>
          </div>
          <div className="upnext--article--wrapper">
            <div id="next-text">
              <h2>Up Next</h2>
            </div>
            <div className="article--list">
              {articleArray
                .slice(0, 3)
                .filter((e: { contentful_id: any }) => e.contentful_id !== contentful_id)
                .map(
                  (articles: {
                    bannerUrl: any
                    titleOfArticle: any
                    metaTitle: any
                    metaDescription: any
                    contentful_id: any
                    slug: any
                  }) => {
                    const { bannerUrl, titleOfArticle, metaTitle, metaDescription, contentful_id, slug } = articles

                    return (
                      <Link
                        to={`/doc/article/doc-article/${slug}`}
                        className="artile--card--wrapper--2"
                        key={contentful_id}
                      >
                        <ArticleCard
                          key={contentful_id}
                          articleImage={bannerUrl.fluid.src}
                          articleHeadtext={titleOfArticle}
                          articleSubtext={metaTitle}
                          meta={metaDescription}
                        />
                      </Link>
                    )
                  }
                )}
            </div>
          </div>
        </div>
      </Layout>
    </div>
  )
}

export default Articlepage
